/**
 * Composable to manage tracking view search results
 */

export function useSearchResultsTracking() {
    const gtm = useGtm();

    const trackViewSearchResults = (searchTerm: string, searchTermTrigger: string) => {
        const event = {
            event: 'view_search_results',
            search_term: searchTerm,
            search_term_trigger: searchTermTrigger,
        };

        gtm?.trackEvent(event);
    };

    return {
        trackViewSearchResults,
    };
}
