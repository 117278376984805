<script setup lang="ts">
import type { AlgoliaProduct } from '~/types/models/algoliaProduct';

const props = defineProps<{
    searchResult: AlgoliaProduct;
    isLoggedIn: boolean;
    indexName: string;
    positionIndex: number;
}>();

const emits = defineEmits<{
    (e: 'add-to-cart-not-allowed' | 'close-search'): void;
}>();

const { searchResult } = toRefs(props);

const { trackViewSearchResults } = useSearchResultsTracking();
const { trackAlgoliaObjectClick, trackAlgoliaObjectAddToCart } = useAlgoliaTracking();
const { typingQuery, productQueryId } = useLayoutStoreSearch();

const { pushInfo } = useNotifications();

const { listingBadges } = useAlgoliaProduct(searchResult.value);
const { pathToSeoUrl } = useLanguageSelect();
const url = computed(() => pathToSeoUrl(props.searchResult.url));

const name = computed<string | undefined>(() => {
    return searchResult.value?._highlightResult?.name?.value ?? searchResult.value?.name ?? undefined;
});
const description = computed<string | undefined>(() => {
    return (
        searchResult.value?._highlightResult?.description_short?.value ??
        searchResult.value?.description_short ??
        undefined
    );
});

const showHover = ref(false);

const { availabilities } = useAvailability();
const availability = computed(() => availabilities.value?.[searchResult.value?.productNumber] ?? []);

const isArchive = computed(() => searchResult.value?.isArchive);

// set product data for addToCart
const { overlayVisible, isAvailable } = useQuantityValidation(props.searchResult);

const onRestrictedAddToCart = (message: string | undefined) => {
    if (!message) return;
    pushInfo(message);
};

const showAddToCart = computed(() => showHover.value && !isArchive.value && props.isLoggedIn);

const onProductClick = () => {
    trackViewSearchResults(typingQuery.value, 'click on product');

    trackAlgoliaObjectClick({
        queryID: productQueryId.value,
        objectIDs: [searchResult.value.objectID],
        positions: [props.positionIndex],
    });

    emits('close-search');
};

const onAddToCartSuccess = () => {
    trackViewSearchResults(typingQuery.value, 'add to cart');

    trackAlgoliaObjectAddToCart({
        queryID: productQueryId.value,
        objectIDs: [searchResult.value.objectID],
    });
};
</script>

<template>
    <NuxtLink
        :to="url"
        data-testid="layout-search-suggest-link"
        @click="onProductClick"
    >
        <div
            class="flex items-center gap-2 lg:gap-4 py-3 md:px-3 text-sm relative"
            :class="{ 'shadow rounded-md': showAddToCart }"
            @mouseenter="showHover = true"
            @mouseleave="showHover = false"
        >
            <div class="overflow-hidden flex-none w-20 h-20 bg-gray-100 rounded-md">
                <SharedImage
                    data-testid="layout-search-suggest-image"
                    :media-src="searchResult?.coverUrl || undefined"
                    class="object-cover w-20"
                    alt="Product image"
                    sizes="xs:80px"
                    width="80"
                    height="80"
                    :lazy-load="false"
                />
            </div>
            <div class="flex flex-col justify-start gap-2 basis-1/2 grow shrink">
                <div class="flex flex-row flex-wrap gap-2">
                    <SharedLabel
                        v-if="searchResult.manufacturer"
                        color="gray-100 whitespace-nowrap !text-gray-500 font-normal"
                        size="lg"
                    >
                        {{ searchResult.manufacturer }}
                    </SharedLabel>
                    <ProductBadges
                        v-if="listingBadges"
                        :badges="listingBadges"
                        no-margin
                    />
                </div>
                <div>
                    <span
                        data-testid="layout-search-suggest-name"
                        class="text-base overflow-hidden font-bold line-clamp-1 leading-6"
                        v-html="name"
                    />
                </div>
                <div class="h-[58px]">
                    <p
                        v-if="!showAddToCart && description"
                        class="line-clamp-2 mb-0 leading-6"
                        v-html="description"
                    />
                </div>
                <Transition
                    enter-active-class="transition duration-150"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition duration-150"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                    mode="out-in"
                >
                    <div
                        v-if="showAddToCart"
                        id="suggest-hover-actions-wrapper"
                        class="absolute right-0 bottom-0 bg-white w-[210px] cursor-default transition-width duration-500"
                        :class="{ 'w-[calc(100%-100px)]': overlayVisible }"
                        @click.prevent.stop
                        @touchstart.passive.stop
                    >
                        <Transition
                            enter-active-class="transition-opacity"
                            enter-from-class="opacity-0"
                            enter-to-class="opacity-100"
                            leave-active-class="transition-opacity"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                        >
                            <SharedQuantityInfo
                                v-if="overlayVisible"
                                class="p-3"
                            />
                        </Transition>
                        <div
                            id="suggest-hover-actions"
                            class="flex flex-row gap-2.5 justify-end items-center grow-1 p-2 rounded-md"
                        >
                            <SharedDeliveryState
                                v-if="isLoggedIn && !isArchive"
                                class="ml-auto"
                                mode="badge"
                                :availability="availability"
                            />

                            <div
                                v-if="!isArchive && isAvailable && isLoggedIn"
                                class="basis-[80px]"
                            >
                                <SharedQuantityInput
                                    :id="'card-quantity-' + searchResult.objectID"
                                    data-testid="product-card-input"
                                    class="h-10.5"
                                />
                            </div>

                            <div class="basis-[80px]">
                                <SharedAddToCart
                                    v-if="isLoggedIn"
                                    :show-restriction-message="false"
                                    :disabled="isArchive || !isAvailable"
                                    @add-to-cart-success="onAddToCartSuccess"
                                    @toggle-restriction-message="onRestrictedAddToCart"
                                />
                                <SharedButton
                                    v-else
                                    color="gray-500"
                                    :outline="true"
                                    class="whitespace-nowrap py-2 px-6 !text-base"
                                    :title="$t('addToCartTitle')"
                                    @click="emits('add-to-cart-not-allowed')"
                                >
                                    {{ $t('productLoginBuyText') }}
                                </SharedButton>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
            <ProductCardPrices
                v-if="searchResult.productNumber"
                :product-number="searchResult.productNumber"
                :is-archive-article="searchResult.isArchive"
                class="basis-1/5 shrink grow whitespace-normal hyphens-auto break-words mb-2.5"
                context="search"
            />
        </div>
    </NuxtLink>
</template>
